import React from 'react';
import { FaGithub, FaStethoscope, FaCat, FaDog, FaServer } from 'react-icons/fa';

import './style.scss';

const Navbar = () => (
	<div className="hero-head is-hidden-mobile">
		<nav className="navbar">
			<div className="container">
				<div className="navbar-brand">
					<span className="navbar-item">
							<a
								className="button is-inverted"
								href="#seccion_cloudvet"
							>
								<span className="icon">
									<FaCat size="fa-2x" />
								</span>
								<span className="icon">
									<FaDog size="fa-2x" />
								</span>
								<span>CloudVet RD</span>
							</a>
					</span>
					<span className="navbar-item">
							<a
								className="button is-inverted"
								href="#seccion_cloudmed"
							>
								<span className="icon">
									<FaStethoscope size="fa-2x" />
								</span>
								<span>CloudMed RD</span>
							</a>
					</span>
					{/* <span className="navbar-item">
							<a
								className="button is-inverted"
								href="https://github.com/amandeepmittal/gatsby-bulma-quickstart"
							>
								<span className="icon">
									<FaServer size="fa-2x" />
								</span>
								<span>Hosting de sitios estáticos</span>
							</a>
					</span> */}
				</div>
				{/* <div id="navbarMenuHeroA" className="navbar-menu">
					<div className="navbar-end">
						<span className="navbar-item">
							<a
								className="button is-danger is-inverted"
								href="https://github.com/amandeepmittal/gatsby-bulma-quickstart"
							>
								<span className="icon">
									<FaGithub size="fa-2x" />
								</span>
								<span>Download</span>
							</a>
						</span>
					</div>
				</div> */}
			</div>
		</nav>
	</div>
);

export default Navbar;
