import React from 'react'
import { FaTwitter, FaGithub, FaMedium } from 'react-icons/fa'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'
import Emoji from './emoji'
import whatsapp_logo from '../images/whatsapp.logo.svg';
import {
	FaLinkedin
} from 'react-icons/fa';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            gatsby
          }
        }
      }
    `}
    render={data => (
      <footer className='footer center has-background-light'>
        <div className='content has-text-centered'>
          <p className='is-size-4'>
          ¿Necesitas ayuda para iniciar tu clínica? Coordinemos una reunión en Google Meet o Zoom.{' '}
          </p>
          <p className='is-size-4'>
          Whatsapp al +5492994620112 
          </p>          

          <div style={{position: "fixed", bottom: "0px", right: "0px", zIndex: "999", padding: "2rem"}}>
            <a href="https://api.whatsapp.com/send?phone=542994620112&text=Hola, coordinemos una reunión en Meet o Zoom para iniciar mi clínica en la nube." target="_blank">
              <img src={whatsapp_logo} alt="logo whatsapp" height="64px" width="64px" />
            </a>
          </div> 

          {/* <article className='media center'>
            <span className='icon'>
              <a href={data.site.siteMetadata.twitter}>
                <FaTwitter size='fa-2x' color='blue' />
              </a>
            </span>
            &nbsp;
            <span className='icon'>
              <a href={data.site.siteMetadata.github}>
                <FaGithub size='fa-2x' color='black' />
              </a>
            </span>
            &nbsp;
            <span className='icon'>
              <a href={data.site.siteMetadata.medium}>
                <FaMedium size='fa-2x' color='green' />
              </a>
            </span>
            &nbsp;
          </article> */}
        </div>
      </footer>
    )}
  />
)

export default Footer
