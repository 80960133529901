import React from 'react';
import {
	FaMobileAlt,
	FaCloud,
	FaRocket,
	FaUserMd,
	FaUnlockAlt,
	FaBitcoin,
	FaFighterJet,
	FaCarSide
} from 'react-icons/fa';

import './style.scss';
import Emoji from './emoji';

const Midsection = () => (
	<div>
		<section className="section">
			<div className="container">
				<div className="columns is-multiline">
					<div className="column is-half">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaMobileAlt size="fa-2x" color="#d22780" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Accede</h1>
									<p className="subtitle is-size-5">
										A la historia de tu paciente desde cualquier dispositivo.
										No necesitarás que te recuerde nada, ya que de un vistaso 
										tendrás una idea del tratamiento que vienes realizando.
										¡Mantén la confianza que tu paciente depositó en tí!
									</p>
								</div>
							</div>
						</article>
					</div>
					{/* <div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaUserMd size="fa-2x" className="has-text-danger" />
								</span>
								<span className="icon is-medium">
									<FaUserMd size="fa-2x" className="has-text-danger" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Colabora</h1>
									<p className="subtitle is-size-5">
										Con otros profesionales armando un equipo de trabajo.
									</p>
								</div>
							</div>
						</article>
					</div> */}
					<div className="column is-half">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaUnlockAlt size="fa-2x" color="#5e227f" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Confía</h1>
									<p className="subtitle is-size-5">
										Tomamos la seguridad en serio. Tus datos y los de tus pacientes estarán siempre seguros.
										Ya que usamos <code>HTTPS</code>.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-half">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<Emoji emoji="🤑" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">¡Ahorra!</h1>
									<p className="subtitle is-size-5">
										¡El primer mes del servicio es gratis! Si nos recomiendas y un colega contrata el servicio puedes tener descuentos de hasta un 30% en tu primer año de suscripción. Puedes dar de baja el servicio en el momento que lo desees y dejaremos de facturar inmediatamente.
									</p>
								</div>
							</div>
						</article>
					</div>

					<div className="column is-half">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaBitcoin size="fa-2x" className="has-text-primary" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">La cuota más baja</h1>
									<p className="subtitle is-size-5">
										 $6USD (ARS $1000 si estás en Argentina). Podrás colaborar con otros 5 usuarios de tu clínica.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaCloud size="fa-2x" className="has-text-primary" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Necesitas pasarte a la nube</h1>
									<p className="subtitle is-size-5">
									<p className='is-size-4'>
									¿Aún llevas tus historias clínicas en papeles, archivos sueltos en una computadora sin backups o en un viejo sistema que ya no tiene mantenimiento? ¡Ya es momento de que comiences a disfrutar de la seguridad y comodidad de la nube! Coordinemos una reunión en Google Meet o Zoom.
									</p>
									<p className='is-size-4'>
									Whatsapp al +5492994620112 
									</p>  
									</p>
								</div>
							</div>
						</article>
					</div>
					{/* <div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaCarSide size="fa-2x" className="has-text-danger" />
								</span>
								-
								<span className="icon is-medium">
									<FaFighterJet size="fa-2x" className="has-text-danger" />
								</span>	
								 -						
								<span className="icon is-medium">
									<FaRocket size="fa-2x" className="has-text-danger" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Actualizaciones</h1>
									<p className="subtitle is-size-5">
										Realizamos mejoras periódicamente tomando en cuenta la seguridad y 
										los comentarios de nuestros clientes.
									</p>
								</div>
							</div>
						</article>
					</div> */}
				</div>
			</div>
		</section>
	</div>
);

export default Midsection;
