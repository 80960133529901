import React from 'react';


import './style.scss';

import redigi_logo from '../images/redigi_logo_2020.svg';
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="hero gradientBg is-fullheight-with-navbar">
		<Navbar />
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<figure className="is-left">
						<span className="icon is-large ">
							<img src={redigi_logo} alt="logo Respuesta Digital" />
						</span>
					</figure>
					<div className="media-content">
						<div className="content">
							<h1 className="is-uppercase is-size-1 has-text-white">
								RESPUESTA DIGITAL
							</h1>
							<p className="subtitle has-text-white is-size-3">
								¿Tienes a tu paciente ante ti y no recuerdas detalles de la última consulta? <br/>
								¿Necesitas un latiguillo como "y... cómo evolucionó eso"? ¡Evita el mal momento! <strong>Con un golpe de vista</strong> y en pocos segundos puedes estar al tanto ¡Eso es una buena calidad de servicio!
							</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default Header;
