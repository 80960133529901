import React from 'react';
import {
	FaSass,
	FaHtml5,
	FaCarSide
} from 'react-icons/fa';

import './style.scss';
import Emoji from './emoji';
import logo_pichichus from '../images/logos_clientes/logo_pichichus_simple.png';
import logo_issn from '../images/logos_clientes/redigi_logoissn.png'
import logo_ipet1 from '../images/logos_clientes/redigi_logoipet1.png'
import logo_muninqn from '../images/logos_clientes/redigi_logomuninqn.png'
import logo_yamilidrogo from '../images/logos_clientes/logo_yamil_idrogo.svg'

const ProductosSeccion = () => (
	<div class="tile is-ancestor">
	<div class="tile is-vertical is-8">
	<div id="seccion_cloudvet" class="tile is-parent">
		<article class="tile is-child notification  is-success">
			<p class="title">CloudVet RD</p>
			<p class="subtitle">Historia Clínica Veterinaria Web</p>

			<p class="content">
					<p>Primero carga las razas que vas a usar.</p>
					<p>Luego registra a un propietario con sus mascotas.</p>
					<p>¡Listo! selecciona una mascota para cargar sus consultas.</p>
			</p>
			<div class="content">

				<a 	className="button is-inverted is-rounded"
					href="https://cloudvet.respuestadigital.com.ar/hiscliapp/login/"
					target="_blank" 
				>
					<span>Acceso al sistema</span>
				</a>

				<a 	className="button is-rounded is-warning"
					href="https://cloudvet.respuestadigital.com.ar/hiscliapp/altaclinica/"
					target="_blank" 
				>
					<span>Crear una clínica</span>
				</a>
			</div>
			<iframe width="631" height="384" src="https://www.youtube.com/embed/r23_8J0gSAY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</article>
		</div>
		<div class="tile">
			<div id="seccion_cloudmed" class="tile is-parent">
				<article class="tile is-child notification is-info">
					<p class="title">CloudMed RD</p>
					<p class="subtitle">Historia clínica Web para profesionales independientes de la salud</p>
					<p class="content">
					Registra tus pacientes, sus antecedentes y sus consultas o intervenciones. Agrega fotos a las consultas.
					</p>
					<div class="content">
						<a 	className="button is-inverted is-rounded"
								href="https://hisclihu.respuestadigital.com.ar/hiscliapp/login/"
								target="_blank" 
							>
								<span>Acceso al sistema</span>
							</a>
					</div>

					<iframe width="631" height="384" src="https://www.youtube.com/embed/-hzseL9SxyY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</article>
			</div>
		</div>

		<div class="tile">
			<div id="seccion_cloudmed_v2" class="tile is-parent">
				<article class="tile is-child notification is-danger">
					<p class="title">CloudMed RD Versión 2 (beta)</p>
					<p class="subtitle">Vamos a liberar la version 2</p>
					<p class="content">
					Nueva experiencia de usuario mejorada gracias a la última tecnología.
					Podrás pasar a la nueva versión conservando todos tus pacientes y consultas. 
					
					</p>
					<div class="content">
						<a 	className="button is-inverted is-rounded"
								href="https://hisclihureact.respuestadigital.com.ar/"
								target="_blank" 
							>
								<span>Acceso a la versión 2</span>
							</a>
					</div>

					<iframe width="631" height="384" src="https://www.youtube.com/embed/JmuLV8MsuOw?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</article>
			</div>
		</div>
	</div>
	<div class="tile is-parent">
		<article class="tile is-child notification is-Light">
		<div class="content">
			<p class="title">Clientes</p>
			<p class="subtitle">Confían en nosotros:</p>
			<div class="content">
				<img src={logo_pichichus} alt="logo Pichichus" />
				<img src={logo_yamilidrogo} alt="logo Yamil Idrogo" />

				<img src={logo_issn} alt="logo ISSN" />
				<img src={logo_muninqn} alt="logo Municipalidad de Neuquén" />

			</div>
		</div>
		</article>
	</div>
	</div>
);

export default ProductosSeccion;
